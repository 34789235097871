/* import __COLOCATED_TEMPLATE__ from './source-statuses.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-foundations */
import templateOnlyComponent from '@ember/component/template-only';

interface Args {
  isLocationOH: boolean;
  hideManageLink: boolean;
  hideUsedByFinStamp?: boolean;
  completionCallback?: Function;
}

const SourceStatuses = templateOnlyComponent<Args>();
export default SourceStatuses;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'ExternalContent::Import::SourceStatuses': typeof SourceStatuses;
  }
}
