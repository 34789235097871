/* import __COLOCATED_TEMPLATE__ from './success.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-foundations */
import templateOnlyComponent from '@ember/component/template-only';
import type ContentImportSource from 'embercom/models/content-service/content-import-source';

interface Signature {
  Args: {
    contentImportSource: ContentImportSource;
    isLocationOH?: boolean;
    hideUsedByFinStamp?: boolean;
  };
}

const Success = templateOnlyComponent<Signature>();
export default Success;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'ExternalContent::Import::Status::Success': typeof Success;
  }
}
