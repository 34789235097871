/* import __COLOCATED_TEMPLATE__ from './content.hbs'; */
/* RESPONSIBLE TEAM: team-ai-agent */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import type Store from '@ember-data/store';
import { EntityType } from 'embercom/models/data/entity-types';
import {
  BULK_ACTION_PROGRESSION_ID,
  type BulkActionProgressionEvent,
  BulkActionProgressionValues,
  type SyncSourceWrapperResponse,
} from 'embercom/services/knowledge-hub-service';
import type KnowledgeHubService from 'embercom/services/knowledge-hub-service';
import type SyncSourceWrapper from 'embercom/models/knowledge-hub/sync-source-wrapper';
import { action } from '@ember/object';
import type IntlService from 'ember-intl/services/intl';
import ENV from 'embercom/config/environment';
import type ConversationExtractionService from 'embercom/services/conversation-extraction-service';
import { type ViewId } from 'embercom/components/knowledge-hub/setup-modal';
import type AiAgentSetupService from 'embercom/services/ai-agent-setup-service';
import { type Action } from 'embercom/components/common/hero-banner';

export enum ContentSourceType {
  PublicArticle = 'public_article',
  Website = 'website',
  Snippet = 'snippet',
  PDF = 'pdf',
  ZendeskArticle = 'zendesk_article',
  ConversationExtraction = 'conversation_extraction',
  CustomAnswer = 'custom-answer',
}

interface Args {
  finIsLive: boolean;
  openSectionId?: string;
  setOpenSectionId: (openSectionId: string) => void;
  showPreview?: boolean;
  togglePreview?: () => void;
}

interface Signature {
  Args: Args;
}

export default class Content extends Component<Signature> {
  entityType = EntityType;
  contentSourceType = ContentSourceType;

  @service declare realTimeEventService: { on: Function; off: Function; subscribeTopics: Function };
  @service declare knowledgeHubService: KnowledgeHubService;
  @service declare conversationExtractionService: ConversationExtractionService;
  @service declare store: Store;
  @service declare appService: any;
  @service declare notificationsService: any;
  @service declare intl: IntlService;
  @service declare aiAgentSetupService: AiAgentSetupService;
  @service declare intercomEventService: { trackAnalyticsEvent: Function };

  @tracked sourceTypeToImport?: ViewId;
  @tracked showSetupModal = false;
  @tracked previewLocale = 'en';
  @tracked activeVideoId?: string;

  constructor(owner: unknown, args: any) {
    super(owner, args);

    this.knowledgeHubService.subscribeToSourceSyncEvents();
    this.conversationExtractionService.subscribeToImportProgressEvents();
    this.setBulkActionProgressActions();

    if (args.openSectionId === 'conversation-content') {
      this.showConversationExtractionModal();
      args.setOpenSectionId('');
    }
    // In case this content is used outside a route that already initialized the usage summary, we need to fetch it
    if (!this.knowledgeHubService.usageSummary) {
      this.knowledgeHubService.fetchKnowledgeUsageSummary();
    }
    if (!this.conversationExtractionService.conversationExtractionSettings) {
      this.conversationExtractionService.fetchConversationExtractionSettings();
    }
    this.aiAgentSetupService.load();

    this.previewLocale = this.appService.app.locale || 'en';
    if (this.appService.app.canUseFinPreviewAutoLanguageDetection) {
      this.previewLocale = 'auto';
    }
  }

  willDestroy(): void {
    super.willDestroy();
    this.destroyNexusEvents();
    this.knowledgeHubService.unsubscribeToSourceSyncEvents();
    this.conversationExtractionService.unsubscribeFromImportProgressEvents();
  }

  private setBulkActionProgressActions() {
    this.realTimeEventService.on(BULK_ACTION_PROGRESSION_ID, this, 'handleBulkActionProgression');
  }

  private destroyNexusEvents() {
    this.realTimeEventService.off(BULK_ACTION_PROGRESSION_ID, this, 'handleBulkActionProgression');
  }

  async handleBulkActionProgression(event: BulkActionProgressionEvent) {
    let currentAdmin = this.appService.app.currentAdmin;
    if (currentAdmin.id !== event.admin_id.toString()) {
      return;
    }

    if (event.status === BulkActionProgressionValues.Complete) {
      if (event.all_entities_unaffected) {
        this.knowledgeHubService.notifyNoEntitiesAffected(event.bulk_action);
      } else {
        this.sendCompletionNotification(event);
        setTimeout(() => {
          this.knowledgeHubService.fetchKnowledgeUsageSummary();
        }, ENV.APP._500MS);
      }
    }

    if (event.status === BulkActionProgressionValues.InvalidFolder) {
      this.notificationsService.notifyError(
        this.intl.t('knowledge-hub.filterable-list.bulk-actions.invalid-folder'),
      );
    }
  }

  private async sendCompletionNotification(event: BulkActionProgressionEvent) {
    let action = event.bulk_action;
    let unaffectedEntityTypes = event.unaffected_entity_types;
    let language_code = event.language_code;
    let destinationFolderId = event.destination_folder_id;
    this.knowledgeHubService.notifyBulkActionCompleted(
      action,
      unaffectedEntityTypes,
      language_code,
      destinationFolderId,
    );
    if (destinationFolderId) {
      await this.knowledgeHubService.fetchFoldersOnce(true);
    }
  }

  get conversationExtractionSettings() {
    return this.conversationExtractionService.conversationExtractionSettings;
  }

  get shouldShowConversationExtractionSection() {
    // If an app is SA then we only want to show this section if canUseExtractionExternalSources  is true, until we release it to all SA apps
    // source https://github.com/intercom/embercom/pull/94249/files#r1946430684
    return (
      !this.appService.app.canUseStandalone || this.appService.app.canUseExtractionExternalSources
    );
  }

  get showEmptyState() {
    return this.totalContentCount === 0 && !this.conversationExtractionSettings?.enabled;
  }

  get totalArticlesCount() {
    return this.knowledgeHubService.usageSummary?.[EntityType.ArticleContent]?.all || 0;
  }

  get enabledArticlesCount() {
    return this.knowledgeHubService.usageSummary?.[EntityType.ArticleContent]?.agent || 0;
  }

  get totalSnippetsCount() {
    return this.knowledgeHubService.usageSummary?.[EntityType.ContentSnippet]?.all || 0;
  }

  get enabledSnippetsCount() {
    return this.knowledgeHubService.usageSummary?.[EntityType.ContentSnippet]?.agent || 0;
  }

  get totalPdfsCount() {
    return this.knowledgeHubService.usageSummary?.[EntityType.FileSourceContent]?.all || 0;
  }

  get enabledPdfsCount() {
    return this.knowledgeHubService.usageSummary?.[EntityType.FileSourceContent]?.agent || 0;
  }

  get totalCustomAnswersCount() {
    return this.knowledgeHubService.usageSummary?.[EntityType.Answer]?.all || 0;
  }

  get enabledCustomAnswersCount() {
    return this.knowledgeHubService.usageSummary?.[EntityType.Answer]?.agent || 0;
  }

  get enabledExternalContentCount() {
    return this.knowledgeHubService.usageSummary?.[EntityType.ExternalContent]?.agent || 0;
  }

  get totalExternalContentCount() {
    let externalContents = this.knowledgeHubService.usageSummary?.[EntityType.ExternalContent];
    return externalContents?.sync_sources.length || 0;
  }

  get totalEnabledContentCount() {
    return (
      this.enabledArticlesCount +
      this.enabledSnippetsCount +
      this.enabledPdfsCount +
      this.enabledExternalContentCount
    );
  }

  get totalContentCount() {
    return (
      this.totalArticlesCount +
      this.totalSnippetsCount +
      this.totalPdfsCount +
      this.totalExternalContentCount
    );
  }

  get disabledArticlesCriteria() {
    return {
      disableBulkEnable: this.enabledArticlesCount === this.totalArticlesCount,
    };
  }

  @action isExternalContentSyncDisabled(syncSource: SyncSourceWrapper): boolean {
    if (
      syncSource.errorType === 'non_retryable_error' ||
      syncSource.errorType === 'no_content' ||
      syncSource.errorType === 'unauthorized'
    ) {
      return true;
    } else {
      return false;
    }
  }

  get disabledSnippetsCriteria() {
    return {
      disableBulkEnable: this.enabledSnippetsCount === this.totalSnippetsCount,
    };
  }

  get disabledPdfsCriteria() {
    return {
      disableBulkEnable: this.enabledPdfsCount === this.totalPdfsCount,
    };
  }

  get externalContentSyncSources(): SyncSourceWrapperResponse[] {
    return this.knowledgeHubService.usageSummary?.[EntityType.ExternalContent]?.sync_sources ?? [];
  }

  get fileSourceSyncSources(): SyncSourceWrapperResponse[] {
    return (
      this.knowledgeHubService.usageSummary?.[EntityType.FileSourceContent]?.sync_sources ?? []
    );
  }

  @action getExternalContentSourceWrapper(
    externalContentSource: SyncSourceWrapperResponse,
  ): SyncSourceWrapper {
    let id = `${externalContentSource.source_type}-${externalContentSource.source_id}`;
    return this.store.peekRecord('knowledge-hub/sync-source-wrapper', id);
  }

  @action goToContent(entityType: EntityType, syncSource?: SyncSourceWrapper) {
    if (entityType === EntityType.ExternalContent && syncSource) {
      this.knowledgeHubService.goToSyncSourceFolder(syncSource, {});
    } else {
      this.knowledgeHubService.goToContent(entityType, {});
    }
  }

  get zendeskSyncSources(): SyncSourceWrapperResponse[] | undefined {
    if (!this.knowledgeHubService.usageSummary) {
      return [];
    }

    return this.knowledgeHubService.usageSummary[EntityType.ArticleContent]?.sync_sources.filter(
      ({ source_type }: { source_type: string }) => source_type === 'zendesk',
    );
  }

  @action getZendeskSyncSourceWrapper(source: SyncSourceWrapperResponse): SyncSourceWrapper {
    let id = `${source.source_type}-${source.source_id}`;
    return this.store.peekRecord('knowledge-hub/sync-source-wrapper', id);
  }

  get salesforceSyncSource(): SyncSourceWrapperResponse | undefined {
    if (!this.knowledgeHubService.usageSummary) {
      return;
    }

    return this.fileSourceSyncSources.find(
      (source: SyncSourceWrapperResponse) => source.source_type === 'salesforce_knowledge',
    );
  }

  get salesforceSyncSourceWrapper(): SyncSourceWrapper | undefined {
    if (!this.salesforceSyncSource) {
      return undefined;
    }
    let id = `${this.salesforceSyncSource.source_type}-${this.salesforceSyncSource.source_id}`;
    return this.store.peekRecord('knowledge-hub/sync-source-wrapper', id);
  }

  @action
  async showModal(syncSourceType: string, entityType: EntityType) {
    try {
      await this.knowledgeHubService.ensurePermissions(entityType);
    } catch (error) {
      return;
    }

    this.sourceTypeToImport = syncSourceType as ViewId;
    this.showSetupModal = true;
  }

  @action
  closeSetupModal() {
    this.showSetupModal = false;
    this.knowledgeHubService.onSummaryChange();
    if (this.sourceTypeToImport === 'conversation-extraction') {
      this.conversationExtractionService.maybeRollbackConversationExtractionSettings();
    }
    this.sourceTypeToImport = undefined;
  }

  @action
  async showConversationExtractionModal() {
    // awaiting here to check permissions
    await this.showModal('conversation-extraction', EntityType.ContentSnippet);
  }

  @action
  goToConversationFolder() {
    this.conversationExtractionService.goToContent(false);
  }

  get hasContentReadyForFin() {
    return this.aiAgentSetupService.hasContentReadyForFin;
  }

  @action
  setPreviewLocale(locale: string) {
    this.previewLocale = locale;
  }

  get newHeroBannerTitle(): string {
    return this.appService.app.canUseStandalone
      ? 'knowledge-hub.new-banner.standalone-title'
      : `knowledge-hub.new-banner.${this.totalEnabledContentCount > 0 ? 'has-content-title' : 'no-content-title'}`;
  }

  get newHeroBannerDescription(): string {
    return this.appService.app.canUseStandalone
      ? 'knowledge-hub.new-banner.standalone-description'
      : `knowledge-hub.new-banner.${this.totalEnabledContentCount > 0 ? 'has-content-description' : 'no-content-description'}`;
  }

  @action
  recordAnalyticsEvent(analyticsId: string) {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: analyticsId,
      place: 'knowledge-hub-overview-header',
    });
  }

  get newHeroBannerActions(): Action[] {
    if (this.appService.app.canUseStandalone) {
      return [
        {
          type: 'link',
          label: 'knowledge-hub.new-banner.actions.sync-articles-from-zendesk',
          icon: 'article',
          analyticsId: 'sync-articles-from-zendesk',
          onClick: () => {
            this.recordAnalyticsEvent('optimizing-content-for-fin-article');
            window.Intercom('showArticle', 10617194); // https://fin.ai/help/en/articles/10617194-sync-from-your-zendesk-help-center
          },
        },
        {
          type: 'link',
          label: 'knowledge-hub.new-banner.actions.sync-manage-websites',
          icon: 'article',
          analyticsId: 'sync-manage-websites',
          onClick: () => {
            this.recordAnalyticsEvent('sync-manage-websites');
            window.Intercom('showArticle', 10617480); // https://fin.ai/help/en/articles/10617480-sync-and-manage-websites
          },
        },
      ];
    }
    if (this.totalEnabledContentCount > 0) {
      return [
        {
          type: 'link',
          label: 'knowledge-hub.new-banner.actions.article-optimizing-content-for-fin',
          icon: 'article',
          analyticsId: 'optimizing-content-for-fin-article',
          onClick: () => {
            this.recordAnalyticsEvent('optimizing-content-for-fin-article');
            window.Intercom('showArticle', 7860255); // https://www.intercom.com/help/en/articles/7860255-optimizing-content-for-fin
          },
        },
      ];
    } else {
      return [
        {
          type: 'link',
          label: 'knowledge-hub.new-banner.actions.fin-ai-agent-features',
          icon: 'article',
          analyticsId: 'fin-ai-agent-features',
          onClick: () => {
            this.recordAnalyticsEvent('fin-ai-agent-features');
            window.Intercom('showArticle', 7120684); // https://www.intercom.com/help/en/articles/7120684-fin-2-intercom-s-latest-generation-of-ai-agent
          },
        },
        {
          type: 'link',
          label: 'knowledge-hub.new-banner.actions.adding-content-for-fin',
          icon: 'article',
          analyticsId: 'adding-content-for-fin',
          onClick: () => {
            this.recordAnalyticsEvent('adding-content-for-fin');
            window.Intercom('showArticle', 7837514); // https://www.intercom.com/help/en/articles/7837514-add-your-support-content-for-fin-ai-agent
          },
        },
      ];
    }
  }

  get bannerDismissalKey() {
    if (this.appService.app.canUseStandalone) {
      return 'standalone-knowledge-hub-overview-header';
    }
    if (this.totalEnabledContentCount > 0) {
      return 'has-content-enabled-knowledge-hub-overview-header';
    }
    return 'no-content-enabled-knowledge-hub-overview-header';
  }

  @action
  setActiveVideo(videoId: string) {
    this.activeVideoId = videoId;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'FinAiAgent::Knowledge::Content': typeof Content;
  }
}
