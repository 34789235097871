/* import __COLOCATED_TEMPLATE__ from './header-card.hbs'; */
/* RESPONSIBLE TEAM: team-purchase */
import templateOnlyComponent from '@ember/component/template-only';
import { type ExpiredSubscriptionBotTarget } from '../expired-subscription';

export enum ExpirationReason {
  FinStandaloneSalesLed = 'fin-standalone-sales-led',
  FinStandaloneSelfServe = 'fin-standalone-self-serve',
  FreeTrialEnded = 'free-trial-ended',
  SubscriptionCancelled = 'subscription-cancelled',
  NonPaymentSelfServe = 'non-payment-self-serve',
  NonPaymentSalesLed = 'non-payment-sales-led',
  NewWorkspace = 'new-workspace',
}

export interface Args {
  heading: string;
  body: string;
  buttons: HeaderCardButton[];
}

export interface HeaderCardButton {
  type: 'primary' | 'secondary';
  label: string;
  action: () => void;
  dataIntercomBotTarget?: ExpiredSubscriptionBotTarget;
}

interface Signature {
  Args: Args;
  Element: HTMLElement;
  Blocks: any;
}

const HeaderCard = templateOnlyComponent<Signature>();
export default HeaderCard;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'ExpiredSubscription::Header::HeaderCard': typeof HeaderCard;
    'expired-subscription/header/header-card': typeof HeaderCard;
  }
}
