/* import __COLOCATED_TEMPLATE__ from './dropdown-actions.hbs'; */
/* RESPONSIBLE TEAM: team-ai-agent */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { AiContentState } from 'embercom/lib/ai-content-library/constants';
import { EntityType } from 'embercom/models/data/entity-types';
import type SyncSourceWrapper from 'embercom/models/knowledge-hub/sync-source-wrapper';
import { inject as service } from '@ember/service';
import type IntlService from 'ember-intl/services/intl';
import type KnowledgeHubService from 'embercom/services/knowledge-hub-service';
import { put } from 'embercom/lib/ajax';
import { restartableTask } from 'ember-concurrency-decorators';
import { taskFor } from 'ember-concurrency-ts';
import {
  ActionName,
  actionParameters,
  type BulkActionApiParams,
} from 'embercom/components/knowledge-hub/filterable-list/bulk-actions/bulk-action-helper';
import { ContentWrapperType } from 'embercom/models/content-service/content-wrapper';
import { ContentSourceType } from './content';
import { tracked } from '@glimmer/tracking';
import type ConversationExtractionService from 'embercom/services/conversation-extraction-service';

export interface DropdownActionsArgs {
  contentSourceType: ContentSourceType;
  syncSource?: SyncSourceWrapper;
  disabledCriteria?: {
    disableBulkEnable: boolean;
    disableResync?: boolean;
    disableRouteToFolder?: boolean;
    disableRemoveSource?: boolean;
  };
  showModal?: (syncSourceType: string, entityType: EntityType) => Promise<void>;
}

export interface DropdownActionsSignature {
  Args: DropdownActionsArgs;
  Blocks: {
    name: [];
    statusLabel: [];
    dropdownActions: [];
  };
}

type SourceRowGroupList = {
  items: {
    text: string;
    onSelectItem?: () => void;
    icon?: string;
    isDisabled?: boolean;
    isDestructive?: boolean;
    tooltipText?: string;
  }[];
};

export default class DropdownActions extends Component<DropdownActionsSignature> {
  @service declare appService: any;
  @service declare intl: IntlService;
  @service declare knowledgeHubService: KnowledgeHubService;
  @service declare conversationExtractionService: ConversationExtractionService;
  @service declare notificationsService: any;
  @service declare permissionsService: any;
  @service declare intercomEventService: any;
  @tracked showDisableConversationExtractionModal = false;

  get app() {
    return this.appService.app;
  }

  get conversationExtractionSettings() {
    return this.conversationExtractionService.conversationExtractionSettings;
  }

  @action async applyBulkEnable(entityType: EntityType, folderId?: number) {
    let { requiredPermission } = actionParameters[ActionName.AIAgentEnable];
    try {
      await this.permissionsService.checkPermission(requiredPermission);
    } catch (e) {
      return;
    }

    await taskFor(this.requestBulkEnable).perform(entityType, folderId);
  }

  @restartableTask private *requestBulkEnable(entityType: EntityType, folderId?: number) {
    let { endpoint } = actionParameters[ActionName.AIAgentEnable];

    try {
      yield put(
        `/ember/knowledge_base/bulk_actions/${endpoint}`,
        folderId
          ? this.bulkActionParamsWithFolderId(entityType, folderId)
          : this.bulkActionParams(entityType),
      );

      this.showAsyncNotification();
    } catch (e) {
      if (
        e.jqXHR.status === 402 &&
        e.jqXHR.responseJSON.errors[0].required_feature[0] === 'help_center'
      ) {
        this.notificationsService.notifyError(
          this.intl.t('knowledge-hub.filterable-list.bulk-actions.missing-help-center-feature'),
        );
        return;
      }
      this.notificationsService.notifyError(
        this.intl.t('knowledge-hub.filterable-list.bulk-actions.failure-banner'),
      );
    }
  }

  bulkActionParamsWithFolderId(entityType: EntityType, folderId: number): BulkActionApiParams {
    let params: BulkActionApiParams = {
      app_id: this.app.id,
      is_bulk_selection: true,
      contents_to_update: null,
      unselected_tuples: [],
      query: null,
    };
    params.query = {
      add_highlight: false,
      additional_searchable_data: {},
      app_id: this.app.id,
      content_wrapper_types: [ContentWrapperType.SUPPORT_CONTENT],
      chatbot_state: AiContentState.NOT_USED_BY_FIN,
      folder_entity_type: EntityType.ContentLibraryFolder,
      folder_ids: [`${folderId}`],
      object_types: [entityType],
      sort_by: 'title',
      sort_direction: 'asc',
    };
    delete params.query.folder_filter_option;

    return params;
  }

  bulkActionParams(entityType: EntityType): BulkActionApiParams {
    let params: BulkActionApiParams = {
      app_id: this.app.id,
      is_bulk_selection: true,
      contents_to_update: null,
      unselected_tuples: null,
      query: null,
    };
    params.query = {
      add_highlight: false,
      app_id: this.app.id,
      content_terms: undefined,
      content_wrapper_types: [ContentWrapperType.SUPPORT_CONTENT],
      object_types: [entityType],
      sort_by: 'title',
      sort_direction: 'asc',
      title: undefined,
    };
    delete params.query.folder_filter_option;

    return params;
  }

  private showAsyncNotification(): void {
    let message = this.intl.t(
      'knowledge-hub.filterable-list.bulk-actions.ai-agent-enable.async-success-banner',
    );

    this.notificationsService.notifyConfirmation(message);
  }

  @action getGroupList(contentSourceType: ContentSourceType): SourceRowGroupList[] {
    switch (contentSourceType) {
      case ContentSourceType.PublicArticle:
        return this.getArticleGroupList();
      case ContentSourceType.Website:
        return this.getWebsiteGroupList();
      case ContentSourceType.Snippet:
        return this.getSnippetGroupList();
      case ContentSourceType.PDF:
        return this.getPDFGroupList();
      case ContentSourceType.ZendeskArticle:
        return this.getZendeskArticleGroupList();
      case ContentSourceType.ConversationExtraction:
        return this.getConversationExtractionGroupList();
      case ContentSourceType.CustomAnswer:
        return this.getCustomAnswersGroupList();
      default:
        return [];
    }
  }

  @action getArticleGroupList(): SourceRowGroupList[] {
    return [
      {
        items: [
          {
            text: this.intl.t('fin-ai-agent.knowledge.actions.articles.enable'),
            onSelectItem: () => {
              this.applyBulkEnable(EntityType.ArticleContent);
              this.instrumentDropdownAction('enable-all', 'public-article');
            },
            icon: 'check',
            isDisabled: this.args.disabledCriteria?.disableBulkEnable,
          },
          {
            text: this.intl.t('fin-ai-agent.knowledge.actions.articles.view-all', {
              isStandaloneApp: this.app.isStandaloneApp,
            }),
            onSelectItem: () => {
              this.knowledgeHubService.goToContent(EntityType.ArticleContent, {}, true);
              this.instrumentDropdownAction('view-all', 'public-article');
            },
            icon: 'campaign',
          },
        ],
      },
    ];
  }

  @action getWebsiteGroupList(): SourceRowGroupList[] {
    let defaultItems = {
      items: [
        {
          text: this.intl.t('fin-ai-agent.knowledge.actions.websites.resync'),
          onSelectItem: () => {
            if (this.args.syncSource?.resync) {
              taskFor(this.args.syncSource.resync).perform();
            }
            this.instrumentDropdownAction('resync', 'website');
          },
          icon: 'sync',
          isDisabled:
            this.args.syncSource?.errorType === 'unauthorized' ||
            this.args.syncSource?.errorType === 'non_retryable_error',
        },
        {
          text: this.intl.t('fin-ai-agent.knowledge.actions.websites.remove'),
          onSelectItem: () => {
            if (!confirm(this.intl.t('fin-ai-agent.knowledge.actions.websites.confirm-remove'))) {
              return;
            }

            this.args.syncSource?.remove();
            this.instrumentDropdownAction('remove', 'website');
          },
          isDestructive: true,
          icon: 'trash',
        },
      ],
    };

    if (this.args.syncSource?.hasError) {
      return [defaultItems];
    }

    if (this.args.syncSource?.status === 'syncing') {
      return [
        {
          items: [
            {
              text: this.intl.t('fin-ai-agent.knowledge.actions.websites.view-all', {
                isStandaloneApp: this.app.isStandaloneApp,
              }),
              onSelectItem: () => {
                if (!this.args.syncSource) {
                  return;
                }

                this.knowledgeHubService.goToSyncSourceFolder(
                  this.args.syncSource,
                  {
                    chatbotState: AiContentState.USED_BY_FIN,
                  },
                  true,
                );
                this.instrumentDropdownAction('view-all', 'website');
              },
              icon: 'campaign',
              isDisabled: this.args.disabledCriteria?.disableRouteToFolder,
            },
            {
              text: this.intl.t('fin-ai-agent.knowledge.actions.websites.remove'),
              onSelectItem: () => {
                if (
                  !confirm(this.intl.t('fin-ai-agent.knowledge.actions.websites.confirm-remove'))
                ) {
                  return;
                }

                this.args.syncSource?.remove();
                this.instrumentDropdownAction('remove', 'website');
              },
              isDestructive: true,
              icon: 'trash',
            },
          ],
        },
      ];
    }

    return [
      {
        items: [
          {
            text: this.intl.t('fin-ai-agent.knowledge.actions.websites.enable'),
            onSelectItem: () => {
              this.applyBulkEnable(EntityType.ExternalContent, this.args.syncSource?.folderId);
              this.instrumentDropdownAction('enable-all', 'website');
            },
            icon: 'check',
            isDisabled: this.args.disabledCriteria?.disableBulkEnable,
          },
          {
            text: this.intl.t('fin-ai-agent.knowledge.actions.websites.view-all', {
              isStandaloneApp: this.app.isStandaloneApp,
            }),
            onSelectItem: () => {
              if (!this.args.syncSource) {
                return;
              }

              this.knowledgeHubService.goToSyncSourceFolder(
                this.args.syncSource,
                {
                  chatbotState: AiContentState.USED_BY_FIN,
                },
                true,
              );
              this.instrumentDropdownAction('view-all', 'website');
            },
            icon: 'campaign',
            isDisabled: this.args.disabledCriteria?.disableRouteToFolder,
          },
          ...defaultItems.items,
        ],
      },
    ];
  }

  @action getSnippetGroupList(): SourceRowGroupList[] {
    return [
      {
        items: [
          {
            text: this.intl.t('fin-ai-agent.knowledge.actions.snippets.enable'),
            onSelectItem: () => {
              this.applyBulkEnable(EntityType.ContentSnippet);
              this.instrumentDropdownAction('enable-all', 'snippet');
            },
            icon: 'check',
            isDisabled: this.args.disabledCriteria?.disableBulkEnable,
          },
          {
            text: this.intl.t('fin-ai-agent.knowledge.actions.snippets.view-all', {
              isStandaloneApp: this.app.isStandaloneApp,
            }),
            onSelectItem: () => {
              this.knowledgeHubService.goToContent(EntityType.ContentSnippet, {}, true);
              this.instrumentDropdownAction('view-all', 'snippet');
            },
            icon: 'campaign',
          },
        ],
      },
    ];
  }

  @action getPDFGroupList(): SourceRowGroupList[] {
    return [
      {
        items: [
          {
            text: this.intl.t('fin-ai-agent.knowledge.actions.pdfs.enable'),
            onSelectItem: () => {
              this.applyBulkEnable(EntityType.FileSourceContent);
              this.instrumentDropdownAction('enable-all', 'pdf');
            },
            icon: 'check',
            isDisabled: this.args.disabledCriteria?.disableBulkEnable,
          },
          {
            text: this.intl.t('fin-ai-agent.knowledge.actions.pdfs.view-all', {
              isStandaloneApp: this.app.isStandaloneApp,
            }),
            onSelectItem: () => {
              this.knowledgeHubService.goToContent(EntityType.FileSourceContent, {}, true);
              this.instrumentDropdownAction('view-all', 'pdf');
            },
            icon: 'campaign',
          },
        ],
      },
    ];
  }

  @action getZendeskArticleGroupList(): SourceRowGroupList[] {
    return [
      {
        items: [
          {
            text: this.intl.t('fin-ai-agent.knowledge.actions.zendesk.resync'),
            onSelectItem: () => {
              if (this.args.syncSource?.resync) {
                taskFor(this.args.syncSource.resync).perform();
              }
              this.instrumentDropdownAction('resync', 'zendesk-article');
            },
            icon: 'sync',
          },
          {
            text: this.intl.t('fin-ai-agent.knowledge.actions.zendesk.remove', {
              isStandaloneApp: this.app.isStandaloneApp,
            }),
            onSelectItem: () => {
              this.args.syncSource?.remove();
              this.instrumentDropdownAction('remove', 'zendesk-article');
            },
            isDestructive: true,
            icon: 'trash',
          },
        ],
      },
    ];
  }

  @action getConversationExtractionGroupList(): SourceRowGroupList[] {
    let disableUpdateSettings =
      this.conversationExtractionSettings?.isLatestImportInProgress ?? false;
    return [
      {
        items: [
          {
            text: this.intl.t(
              'fin-ai-agent.knowledge.actions.conversation-extraction.update-settings',
            ),
            onSelectItem: async () => {
              await this.args.showModal?.('conversation-extraction', EntityType.ContentSnippet);
              this.instrumentDropdownAction('update-settings', 'conversation-extraction');
            },
            icon: 'settings',
            isDisabled: disableUpdateSettings,
            tooltipText: disableUpdateSettings
              ? this.intl.t(
                  'knowledge-hub.conversational.conversation-extraction-settings.settings-blocked-reason.import-in-progress',
                )
              : undefined,
          },
          {
            text: this.intl.t(
              'fin-ai-agent.knowledge.actions.conversation-extraction.view-content',
              {
                isStandaloneApp: this.app.isStandaloneApp,
              },
            ),
            onSelectItem: async () => {
              await this.conversationExtractionService.goToContent();
              this.instrumentDropdownAction('view-content', 'conversation-extraction');
            },
            icon: 'article',
          },
          ...(!this.app.isStandaloneApp
            ? [
                {
                  text: this.intl.t(
                    'fin-ai-agent.knowledge.actions.conversation-extraction.give-feedback',
                  ),
                  onSelectItem: async () => {
                    this.conversationExtractionService.openFeedbackSurvey();
                    this.instrumentDropdownAction('give-feedback', 'conversation-extraction');
                  },
                  icon: 'survey-filled',
                },
              ]
            : []),
          {
            text: this.intl.t(
              'fin-ai-agent.knowledge.actions.conversation-extraction.disable-feature',
            ),
            onSelectItem: async () => {
              this.openShowDisableConversationExtractionModal();
              this.instrumentDropdownAction('disable-feature-modal', 'conversation-extraction');
            },
            icon: 'trash',
            isDestructive: true,
          },
        ],
      },
    ];
  }

  @action getCustomAnswersGroupList(): SourceRowGroupList[] {
    return [
      {
        items: [
          {
            text: this.intl.t('fin-ai-agent.knowledge.actions.custom-answers.view-all'),
            onSelectItem: () => {
              this.knowledgeHubService.goToContent(EntityType.Answer, {}, true);
              this.instrumentDropdownAction('view-all', 'custom_answer');
            },
            icon: 'respond',
          },
        ],
      },
    ];
  }

  @action openShowDisableConversationExtractionModal() {
    this.showDisableConversationExtractionModal = true;
  }

  @action async disableConversationExtraction() {
    await this.conversationExtractionService.disableFeature(() => {
      this.showDisableConversationExtractionModal = false;
    });
  }

  @action instrumentDropdownAction(actionName: string, source: string): void {
    this.intercomEventService.trackAnalyticsEvent({
      tab: 'content',
      action: actionName,
      object: source,
      place: 'fin_ai_agent_knowledge',
      route: 'setup',
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'FinAiAgent::Knowledge::DropdownActions': typeof DropdownActions;
  }
}
