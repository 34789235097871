/* import __COLOCATED_TEMPLATE__ from './go-further-card.hbs'; */
/* RESPONSIBLE TEAM: team-purchase */
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { type Router } from '@ember/routing';
import {
  PRICING_5_X_EARLY_STAGE_SOLUTION_ID,
  percentDiscountForSolution,
} from 'embercom/lib/billing';
import safeWindowOpen from 'embercom/lib/safe-window-open';
import { showNewMessageInIntercomWidget } from 'embercom/lib/intercom-widget-helper';
import { ExpiredSubscriptionBotTarget } from '../expired-subscription';

export enum CardType {
  SaveWithEarlyStage = 'save-with-early-stage',
  SpeakToASpecialistSmall = 'speak-to-a-specialist-small',
  SpeakToASpecialistLarge = 'speak-to-a-specialist-large',
  HaveQuestions = 'have-questions',
  UpcomingFeatures = 'upcoming-features',
  WorkspaceSetupGuide = 'workspace-setup-guide',
  SuccessStories = 'success-stories',
  AboutFinForZenDesk = 'about-fin-for-zen-desk',
  SpeakToASpecialistFin = 'speak-to-a-specialist-fin',
}

export interface Args {
  cardType: CardType;
}

interface Signature {
  Args: Args;
  Blocks: any;
  Element: HTMLElement;
}

interface Config {
  heading: string;
  body: string;
  button: {
    text: string;
    onClick?: () => void;
    dataIntercomBotTarget?: string;
  };
}

export default class GoFurtherCard extends Component<Signature> {
  @service declare intl: any;
  @service declare router: Router;
  @service declare purchaseAnalyticsService: $TSFixMe;
  @service declare appService: $TSFixMe;

  get cardType() {
    return this.args.cardType;
  }

  get heading() {
    return this.cardData.heading;
  }

  get body() {
    return this.cardData.body;
  }

  get buttonText() {
    return this.cardData.button.text;
  }

  get dataIntercomBotTarget() {
    return this.cardData.button.dataIntercomBotTarget;
  }

  @action
  onButtonClick() {
    return this.cardData.button.onClick?.();
  }

  get cardData() {
    return this.config[this.cardType];
  }

  get earlyStageYearOneDiscountPercentage() {
    return percentDiscountForSolution(PRICING_5_X_EARLY_STAGE_SOLUTION_ID, 1);
  }

  private get config(): Record<CardType, Config> {
    return {
      [CardType.SaveWithEarlyStage]: {
        heading: this.intl.t('expired-subscription.go-further-card.save-with-early-stage.heading', {
          earlyStageYearOneDiscountPercentage: this.earlyStageYearOneDiscountPercentage,
        }),
        body: this.intl.t('expired-subscription.go-further-card.save-with-early-stage.body'),
        button: {
          text: this.intl.t(
            'expired-subscription.go-further-card.save-with-early-stage.button-text',
          ),
          onClick: () => this.transitionToEarlyStageApplication(),
        },
      },
      [CardType.SpeakToASpecialistSmall]: {
        heading: this.intl.t(
          'expired-subscription.go-further-card.speak-to-a-specialist-small.heading',
        ),
        body: this.intl.t('expired-subscription.go-further-card.speak-to-a-specialist-small.body'),
        button: {
          text: this.intl.t(
            'expired-subscription.go-further-card.speak-to-a-specialist-small.button-text',
          ),
          onClick: () => {
            this.sendAnalyticsEvent({
              object: 'open_intercom_widget_button',
              extra: {
                card_type: this.cardType,
                data_intercom_bot_target:
                  ExpiredSubscriptionBotTarget.ExpiredSubscriptionSpeakToSalesAssist,
              },
            });
          },
          dataIntercomBotTarget: ExpiredSubscriptionBotTarget.ExpiredSubscriptionSpeakToSalesAssist,
        },
      },
      [CardType.SpeakToASpecialistLarge]: {
        heading: this.intl.t(
          'expired-subscription.go-further-card.speak-to-a-specialist-large.heading',
        ),
        body: this.intl.t('expired-subscription.go-further-card.speak-to-a-specialist-large.body'),
        button: {
          text: this.intl.t(
            'expired-subscription.go-further-card.speak-to-a-specialist-large.button-text',
          ),
          onClick: () => {
            this.sendAnalyticsEvent({
              object: 'open_intercom_widget_button',
              extra: {
                card_type: this.cardType,
                data_intercom_bot_target:
                  ExpiredSubscriptionBotTarget.ExpiredSubscriptionSpeakToSdr,
              },
            });
          },
          dataIntercomBotTarget: ExpiredSubscriptionBotTarget.ExpiredSubscriptionSpeakToSdr,
        },
      },
      [CardType.HaveQuestions]: {
        heading: this.intl.t('expired-subscription.go-further-card.have-questions.heading'),
        body: this.intl.t('expired-subscription.go-further-card.have-questions.body', {
          htmlSafe: true,
        }),
        button: {
          text: this.intl.t('expired-subscription.go-further-card.have-questions.button-text'),
          onClick: () => {
            this.sendAnalyticsEvent({
              object: 'open_messenger_button',
              extra: {
                card_type: this.cardType,
                data_intercom_bot_target:
                  ExpiredSubscriptionBotTarget.ExpiredSubscriptionFrozenForNonPayment,
              },
            });
          },
          dataIntercomBotTarget:
            ExpiredSubscriptionBotTarget.ExpiredSubscriptionFrozenForNonPayment,
        },
      },
      [CardType.UpcomingFeatures]: {
        heading: this.intl.t('expired-subscription.go-further-card.upcoming-features.heading'),
        body: this.intl.t('expired-subscription.go-further-card.upcoming-features.body'),
        button: {
          text: this.intl.t('expired-subscription.go-further-card.upcoming-features.button-text'),
          onClick: () => this.openUpcomingFeaturesPage(),
        },
      },
      [CardType.WorkspaceSetupGuide]: {
        heading: this.intl.t('expired-subscription.go-further-card.workspace-setup-guide.heading'),
        body: this.intl.t('expired-subscription.go-further-card.workspace-setup-guide.body'),
        button: {
          text: this.intl.t(
            'expired-subscription.go-further-card.workspace-setup-guide.button-text',
          ),
          onClick: () => this.openWorkspaceSetupGuide(),
        },
      },
      [CardType.SuccessStories]: {
        heading: this.intl.t('expired-subscription.go-further-card.success-stories.heading'),
        body: this.intl.t('expired-subscription.go-further-card.success-stories.body'),
        button: {
          text: this.intl.t('expired-subscription.go-further-card.success-stories.button-text'),
          onClick: () => this.openSuccessStoriesPage(),
        },
      },
      [CardType.SpeakToASpecialistFin]: {
        heading: this.intl.t(
          'expired-subscription.go-further-card.speak-to-a-specialist-small.heading',
        ),
        body: this.intl.t('expired-subscription.go-further-card.speak-to-a-specialist-small.body'),
        button: {
          text: this.intl.t(
            'expired-subscription.go-further-card.speak-to-a-specialist-small.button-text',
          ),
          onClick: () => this.openMessenger(),
        },
      },
      [CardType.AboutFinForZenDesk]: {
        heading: this.intl.t('expired-subscription.go-further-card.about-fin-for-zen-desk.heading'),
        body: this.intl.t('expired-subscription.go-further-card.about-fin-for-zen-desk.body'),
        button: {
          text: this.intl.t(
            'expired-subscription.go-further-card.about-fin-for-zen-desk.button-text',
          ),
          onClick: () => this.openAboutFinForZenDeskPage(),
        },
      },
    };
  }

  transitionToEarlyStageApplication() {
    this.sendAnalyticsEvent({
      object: 'apply_for_early_stage_button',
    });
    this.router.transitionTo(
      'apps.app.teams-checkout.early-stage-application',
      this.appService.app.id,
      {
        queryParams: {
          solution_id: PRICING_5_X_EARLY_STAGE_SOLUTION_ID,
        },
      },
    );
  }

  openSuccessStoriesPage() {
    this.sendAnalyticsEvent({
      object: 'open_success_stories_button',
    });
    safeWindowOpen('https://www.intercom.com/customers#all-stories', '_blank');
  }

  openUpcomingFeaturesPage() {
    this.sendAnalyticsEvent({
      object: 'open_upcoming_features_button',
    });
    safeWindowOpen('https://www.intercom.com/changes/en', '_blank');
  }

  openWorkspaceSetupGuide() {
    this.sendAnalyticsEvent({
      object: 'open_workspace_setup_guide_button',
    });
    safeWindowOpen(
      'https://www.intercom.com/help/en/collections/2094824-setting-up-your-workspace',
      '_blank',
    );
  }

  openAboutFinForZenDeskPage() {
    this.sendAnalyticsEvent({
      object: 'open_about_fin_for_zendesk_button',
    });
    safeWindowOpen('https://fin.ai/help/en/articles/10602087-fin-for-zendesk-explained', '_blank');
  }

  openMessenger() {
    this.sendAnalyticsEvent({
      object: 'open_messenger_button',
    });
    showNewMessageInIntercomWidget();
  }

  @action
  sendAnalyticsEvent({ object, extra }: { object: string; extra?: any }) {
    this.purchaseAnalyticsService.trackEvent({
      action: 'clicked',
      object,
      context: 'expired_subscription',
      place: 'expired_subscription_page_go_further_card',
      extra: {
        card_type: this.cardType,
        ...extra,
      },
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'ExpiredSubscription::GoFurther::GoFurtherCard': typeof GoFurtherCard;
    'expired-subscription/go-further/go-further-card': typeof GoFurtherCard;
  }
}
