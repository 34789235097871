/* import __COLOCATED_TEMPLATE__ from './progress-bar.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-foundations */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { CONTENT_CREATION_RUN_STATUSES } from 'embercom/lib/external-content/constants';
import type ContentImportRun from 'embercom/models/content-service/content-import-run';
import type IntlService from 'embercom/services/intl';

interface Args {
  contentImportRun: ContentImportRun;
}
export default class ProgressBar extends Component<Args> {
  @service declare intl: IntlService;

  get contentImportRun() {
    return this.args.contentImportRun;
  }

  get totalStepCount() {
    //adding one here as we don't have a run status for waiting for ingestion
    return CONTENT_CREATION_RUN_STATUSES.length + 1;
  }

  get aiIngestionIsInProgress() {
    return this.contentImportRun?.ingestionInProgress ?? false;
  }

  get currentStep() {
    if (this.aiIngestionIsInProgress) {
      return this.totalStepCount;
    }
    return CONTENT_CREATION_RUN_STATUSES.indexOf(this.contentImportRun?.status) + 1;
  }

  get titleForStep() {
    if (this.aiIngestionIsInProgress) {
      return this.intl.t(
        `components.external-content.import.in-progress.titles.waiting-for-fingestion`,
      );
    } else {
      return this.intl.t(
        `components.external-content.import.in-progress.titles.${this.contentImportRun?.status}`,
      );
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'ExternalContent::Import::Status::ProgressBar': typeof ProgressBar;
  }
}
